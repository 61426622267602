<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Move Contacts</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleMoveContacts" v-slot="{ errors }">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Move Contact to {{ !user.is_organization_owner ? 'primary' : '' }} location</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.location }">
                                    <Field autocomplete="off" name="location" v-model="form.location_id" rules="required">
                                        <!-- :label="user.is_organization_owner ? 'company_name' : 'full_name'" -->
                                        <multiselect
                                            v-model="form.location_id"
                                            label="company_name"
                                            value-prop="id"
                                            :options="user.is_organization_owner ? organizationLocations : [{id: companyLocations.company ? companyLocations.company.id : '', company_name: companyLocations.company ? companyLocations.company.full_name : ''}]"
                                            :searchable="true"
                                            placeholder="Select"
                                            :disabled="!user.is_organization_owner"
                                        >
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="location" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp" v-if="locationTabs.length">
                            <div class="group_item">
                                <label class="input_label">Select segment</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.status_tab }">
                                    <Field autocomplete="off" name="status_tab" v-model="form.status" rules="required" label="tab">
                                        <multiselect
                                            v-model="form.status"
                                            :groups="true"
                                            :searchable="true"
                                            :options="locationTabs"
                                            valueProp="value"
                                            label="title"
                                            placeholder="Select segment"
                                            :group-select="false"
                                            group-label="type"
                                            group-options="items"
                                            :group-hide-empty="true"
                                        >
                                            <template v-slot:grouplabel="{ group }">
                                                <span class="status-tabs-header">{{ group.type }}</span>
                                            </template>
                                            <template v-slot:option="{ option }">
                                                <span>{{ option.title }}</span>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="status_tab" class="text-danger" />
                            </div>
                        </div>
                        <label for="privileges" class="switch_option capsule_btn">
                            <h5>Allow Edit Privileges?</h5>
                            <input type="checkbox" id="privileges" v-model="form.edit_privileges" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <label for="alldata" class="switch_option capsule_btn">
                            <h5>Move All Data?</h5>
                            <input type="checkbox" id="alldata" v-model="form.moved_with_data" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <!-- <label for="allow" class="switch_option capsule_btn">
                            <h5>Notify User?</h5>
                            <input type="checkbox" id="allow" v-model="form.is_notify" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-if="form.is_notify">
                            <h3 class="sub_header">Notification Method</h3>
                            <div class="edit_section">
                                <label for="mail" class="switch_option capsule_btn">
                                    <h5>E-mail<span>Send E-mail Notification.</span></h5>
                                    <input type="radio" id="mail" value="1" v-model="form.sending_method" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                                <label for="sms" class="switch_option capsule_btn">
                                    <h5>SMS<span>Send SMS Notification.</span></h5>
                                    <input type="radio" id="sms" value="2" v-model="form.sending_method" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                                <label for="both" class="switch_option capsule_btn">
                                    <h5>Both<span>Send E-mail &amp; SMS Notification</span></h5>
                                    <input type="radio" id="both" value="3" v-model="form.sending_method" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                        </div> -->
                        <div class="action_wpr mt-5">
                            <button :disabled="bulkActionLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i>{{ bulkActionLoader ? ' Moving' : 'Move' }}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate';
    import { mapState, mapActions } from 'vuex';

    export default {
        name: 'MoveContact',

        data () {
            return {
                form: {
                    contacts: [],
                    edit_privileges: 1,
                    moved_with_data: 1,
                    is_notify: 0,
                    sending_method: 1,
                    location_id:'',
                    status: '',
                    status_tab: [],
                    organization_id: '',
                    organization_location_id: '',
                },
                locations: [],
            }
        },

        props: {
            modelValue: Boolean,
            contacts: [Object, Array],
        },

        emits: ['update:modelValue'],

        watch: {
            'form.location_id' () {
                const vm = this;
                vm.locations = [];

                if (vm.user.is_organization_owner) {
                    if (vm.form.location_id) {
                        vm.getLocationTabs(vm.form.location_id).then(() => {
                            vm.locations = vm.locationTabs
                        });
                    }
                } else {
                    if (vm.form.location_id) {
                        vm.getOrganizationTabs(vm.form.location_id).then(() => {
                            vm.locations = vm.locationTabs
                        });
                    }
                }
            },

            'form.status' (val) {
                const vm = this;

                if (val) {
                    const status = Object.values(vm.statusList).find(status => status.value ===  val);

                    vm.form.status_tab = status;
                }
            },

            modelValue(val) {
                const vm = this;

                if (val == true) {
                    if (!vm.user.is_organization_owner) {
                        vm.form.location_id = vm.companyLocations.company.id;
                    }

                    document.body.classList.add('modal-open');
                } else {
                    vm.locations = [];
                    vm.form      = {
                                        contacts: [],
                                        edit_privileges: 1,
                                        moved_with_data: 1,
                                        is_notify: 0,
                                        sending_method: 1,
                                        location_id:'',
                                        status: '',
                                        status_tab: [],
                                        organization_id: '',
                                        organization_location_id: '',
                                    };

                    document.body.classList.remove('modal-open');
                }
            },
        },

        components: {
            Form,
            Field,
            ErrorMessage,
        },

        computed: {
            ...mapState({
                organizationLocations: state => state.authModule.organizationLocations,
                locationTabs: state => state.authModule.locationTabs,
                bulkActionLoader: state => state.contactModule.bulkActionLoader,
                statusList: state => state.contactModule.statusList,
                user: state => state.authModule.user,
                companyLocations: state => state.authModule.companyLocations,
            }),
        },

        mounted () {
            const vm = this;

            if (vm.organizationLocations.length == 0) {
                vm.getOrganizationLocations();
            }
        },

        methods: {
            ...mapActions({
                getOrganizationLocations: 'authModule/getOrganizationLocations',
                getLocationTabs: 'authModule/getLocationTabs',
                moveContacts: 'contactModule/moveContacts',
                getOrganizationTabs: 'authModule/getOrganizationTabs',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleMoveContacts (params, { setFieldError }) {
                const vm = this;

                if (vm.user.is_organization_owner) {
                    vm.form.organization_location_id = vm.form.location_id;
                } else {
                    vm.form.organization_id = vm.form.location_id;
                }

                vm.form.contacts      = vm.contacts;
                vm.form.setFieldError = setFieldError;
                vm.form.closeModal    = vm.closeModal;

                vm.moveContacts(vm.form);
            },
        },
    }
</script>
